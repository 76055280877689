.footerSectionCustom{
    display: grid;
    grid-template-columns: 1fr 2fr;
}


@media (max-width:768px) {
    .footerSectionCustom{
        grid-template-columns: 1fr;
    }

    .footerSectionCustom .dsn-col-footer{
        grid-row: 2/3;
        margin-top: 30px;
    }
}