@import "../../assets/sass/helper";

.next-page {
  position: relative;
  background-color: $assistant-color;
  overflow: hidden;

  &:before {
    content: "";
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: $bg-color;
    transition: width 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .c-wrap {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      overflow: hidden;

      @include media_768(min) {
        flex-flow: row;
        justify-content: center;
        align-items: center;
      }
      @include media_768() {
        flex-direction: column;
        .career {
          margin-top: 10px;
        }
      }


      .hiring, .career {
        position: relative;
        display: block;
        @include media_768(min) {
          width: 50%;
          &.career {
            text-align: right;
          }
        }
      }

      .hiring {
        font-size: 30px;
        font-weight: 800;
        line-height: 1.2;
        letter-spacing: 0.01em;
        color: transparent;
        -webkit-text-stroke: 1px $heading-color;
        text-transform: uppercase;
        transition: color 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        text-align: center;
        margin-bottom: 10px;
      }



      .career {
        color: $heading-color;
        font-size: 14px;
      }
    }
  }

  &:hover {
    &:before {
      width: 100%;
    }

    a {
      .hiring {
        color: $heading-color;
      }
    }
  }
}
